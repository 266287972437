import React from 'react';
import EmailForm from '../shared/EmailForm';
import { motion } from 'framer-motion';

export const Newsletter = () => {
  return (
    <section 
      id="newsletter"
      className="bg-white py-20" 
      aria-labelledby="newsletter-heading"
    >
      <div className="max-w-7xl mx-auto px-6">
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 
            id="newsletter-heading"
            className="text-4xl font-bold mb-4"
          >
            Stay Updated
          </h2>
          <p className="text-gray-600">
            Get the latest information on Crystal's release.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <EmailForm />
        </motion.div>
      </div>
    </section>
  );
};

export default Newsletter;
