import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ROUTES } from '../shared/router/routes';
import logo from '../../assets/logo.png';
import { useLocation } from 'react-router-dom';

const navigationItems = [
  { 
    label: 'Features', 
    href: '#corefeatures', 
    ariaLabel: 'Features section', 
    isExternal: false,
    hoverColor: '#ff6b6b'
  },
  { 
    label: 'Whitepaper', 
    href: './whitepaper.pdf', 
    ariaLabel: 'Download whitepaper', 
    isExternal: true,
    hoverColor: '#4dabf7'
  },
  { 
    label: 'Use Cases', 
    href: '#case-studies', 
    ariaLabel: 'Use-cases section', 
    isExternal: false,
    hoverColor: '#9775fa'
  },
  { 
    label: 'Blog', 
    href: ROUTES.BLOG, 
    ariaLabel: 'View blog posts', 
    isExternal: false,
    hoverColor: '#F4C430'
  }
];

export const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen && window.innerWidth < 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isHomePage = location.pathname === '/' || location.pathname === ROUTES.HOME;

  const handleNavigation = (href, isExternal) => {
    if (isExternal) {
      window.open(href, '_blank', 'noopener,noreferrer');
      return;
    }

    if (href.startsWith('#')) {
      if (isHomePage) {
        document.querySelector(href)?.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.location.href = ROUTES.HOME + href;
      }
      return;
    }

    window.location.href = href;
  };

  useEffect(() => {
    if (isHomePage && location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [isHomePage, location.hash]);

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 px-6 py-4 transition-all duration-300
        ${isScrolled || isMobileMenuOpen ? 'bg-white shadow-md' : 'bg-transparent'}`}
    >
      <nav className="max-w-7xl mx-auto flex items-center justify-between" aria-label="Main navigation">
        <motion.div
          whileHover={{ scale: 1.0737 }}
          whileTap={{ scale: 0.95 }}
          style={{ transformOrigin: 'left center' }}
          onClick={() => handleNavigation(ROUTES.HOME)}
          className="flex items-center space-x-2 cursor-pointer"
        >
          <div className="w-8 h-8 rounded flex items-center justify-center">
            <img src={logo} alt="XTAL Logo" className="w-full h-full object-contain" />
          </div>
          <span className="text-xl font-medium text-gray-800" style={{ fontFamily: 'Space Grotesk', fontSize: '1.5rem' }}>
            Crystal
          </span>
        </motion.div>

        <ul className="hidden md:flex space-x-8">
          {navigationItems.map(({ label, href, ariaLabel, isExternal, hoverColor }) => (
            <li key={href}>
              <button
                onClick={() => handleNavigation(href, isExternal)}
                onMouseEnter={() => setHoveredItem(href)}
                onMouseLeave={() => setHoveredItem(null)}
                className="relative text-gray-800 font-bold transition-colors duration-200"
                style={{ fontFamily: 'Space Grotesk' }}
                aria-label={ariaLabel}
              >
                {label}
                <AnimatePresence>
                  {hoveredItem === href && (
                    <motion.div
                      className="absolute bottom-[-1.5px] left-0 right-0 h-[1.5px]"
                      initial={{ scaleX: 0 }}
                      animate={{ scaleX: 1 }}
                      exit={{ scaleX: 0 }}
                      transition={{
                        type: "tween",
                        duration: 0.2,
                        ease: "easeInOut"
                      }}
                      style={{
                        backgroundColor: hoverColor,
                        transformOrigin: "center"
                      }}
                    />
                  )}
                </AnimatePresence>
              </button>
            </li>
          ))}
        </ul>

        <button 
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          className="md:hidden text-gray-800 focus:outline-none relative w-6 h-6"
          aria-label="Toggle menu"
          aria-expanded={isMobileMenuOpen}
        >
          <motion.span
            className="absolute top-3 left-0 w-6 h-0.5 bg-current origin-center"
            animate={{
              rotate: isMobileMenuOpen ? 45 : 0,
              y: isMobileMenuOpen ? 0 : -4
            }}
            transition={{ duration: 0.2 }}
          />
          <motion.span
            className="absolute top-3 left-0 w-6 h-0.5 bg-current origin-center"
            animate={{
              rotate: isMobileMenuOpen ? -45 : 0,
              y: isMobileMenuOpen ? 0 : 4
            }}
            transition={{ duration: 0.2 }}
          />
        </button>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.ul
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-lg py-4"
            >
              {navigationItems.map(({ label, href, ariaLabel, isExternal, hoverColor }) => (
                <li key={href} className="w-full">
                  <div className="relative px-6 py-2">
                    <button
                      onClick={() => {
                        handleNavigation(href, isExternal);
                        setMobileMenuOpen(false);
                      }}
                      onMouseEnter={() => setHoveredItem(href)}
                      onMouseLeave={() => setHoveredItem(null)}
                      className="text-gray-800 font-bold text-left relative inline-block"
                      style={{ fontFamily: 'Space Grotesk' }}
                      aria-label={ariaLabel}
                    >
                      {label}
                      <motion.span
                        className="absolute bottom-[-1.5px] left-0 h-[1.5px]"
                        initial={{ width: 0, backgroundColor: 'transparent' }}
                        animate={{
                          width: hoveredItem === href ? '100%' : 0,
                          backgroundColor: hoveredItem === href ? hoverColor : 'transparent'
                        }}
                        transition={{ duration: 0.2 }}
                      />
                    </button>
                  </div>
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
};