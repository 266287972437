import React from 'react';

const PostCard = ({ post }) => {
  const { title, excerpt, author, featuredImage, imageAlt, href } = post;

  return (
    <article className="h-full flex flex-col bg-white overflow-hidden">
      {featuredImage && (
        <a href={href} className="block relative w-full pt-[100%]">
          <img
            src={featuredImage}
            alt={imageAlt}
            className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
        </a>
      )}
      <div className="p-4 flex flex-col flex-grow">
        <h2 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2 min-h-[3rem]">
          <a href={href} className="hover:text-blue-600 transition-colors">
            {title}
          </a>
        </h2>
        <p className="text-gray-700 text-base leading-[1.5rem] line-clamp-3 mb-4 flex-grow min-h-[4.5rem]">
          {excerpt}
        </p>
        <div className="flex items-center justify-between text-sm mt-auto">
          <span className="text-gray-500">
            By {author}
          </span>
          <a href={href} className="text-blue-600 hover:underline">
            Read More
          </a>
        </div>
      </div>
    </article>
  );
};

export default PostCard;
