import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const EmailForm = () => {
  const [state, handleSubmit] = useForm("xwpkjlba");
  
  if (state.succeeded) {
    return (
      <div className="w-full max-w-md mx-auto">
        <div className="p-4 rounded-lg bg-green-50 text-green-800">
          Thank you for subscribing!
        </div>
      </div>
    );
  }
  
  return (
    <div className="w-full max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <input
            id="email"
            type="email"
            name="email"
            placeholder="you@example.com"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9775fa] focus:border-transparent text-gray-900 placeholder-gray-400"
            required
          />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
            className="text-sm text-red-600"
          />
        </div>
        <button
          type="submit"
          disabled={state.submitting}
          className="w-full px-6 py-3 bg-[#9775fa] text-white rounded-lg hover:bg-[#7b5fc8] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {state.submitting ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
    </div>
  );
};

export default EmailForm;
