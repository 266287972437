import React from 'react';
import { Blocks, Shield, Zap } from 'lucide-react';
import EmailForm from '../components/shared/EmailForm';

const ComingSoon = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="container mx-auto py-24">
        <div className="max-w-4xl mx-auto text-center space-y-16">
          {/* Hero Section */}
          <div className="space-y-12 py-24"> {/* Increased padding here */}
            {/* Main Content */}
            <div className="space-y-8">
              <h1 className="py-12 text-6xl md:text-7xl font-bold" style={{ fontFamily: 'Space Grotesk', background: 'linear-gradient(to right, #4dabf7, #9775fa, #ff6b6b)', WebkitBackgroundClip: 'text', color: 'transparent' }}>
                Crystal is coming soon
              </h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
                Experience the future of personal and business networking. We're building a revolutionary platform to bring blockchain technology mainstream.
              </p>
            </div>

            {/* Feature Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-3xl mx-auto">
              <div className="p-6 bg-white rounded-2xl shadow-sm hover:shadow-md transition-all group">
                <div className="bg-[#9775fa]/10 w-12 h-12 rounded-xl flex items-center justify-center mx-auto mb-4 group-hover:scale-110 transition-transform">
                  <Blocks className="w-6 h-6 text-[#9775fa]" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2" style={{ fontFamily: 'Space Grotesk' }}>Next-Gen Design</h3>
                <p className="text-gray-600">Unmatched distributed networking experience</p>
              </div>
              <div className="p-6 bg-white rounded-2xl shadow-sm hover:shadow-md transition-all group">
                <div className="bg-[#4dabf7]/10 w-12 h-12 rounded-xl flex items-center justify-center mx-auto mb-4 group-hover:scale-110 transition-transform">
                  <Shield className="w-6 h-6 text-[#4dabf7]" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2" style={{ fontFamily: 'Space Grotesk' }}>Enterprise Security</h3>
                <p className="text-gray-600">Industry-leading security with advanced encryption</p>
              </div>
              <div className="p-6 bg-white rounded-2xl shadow-sm hover:shadow-md transition-all group">
                <div className="bg-[#ff6b6b]/10 w-12 h-12 rounded-xl flex items-center justify-center mx-auto mb-4 group-hover:scale-110 transition-transform">
                  <Zap className="w-6 h-6 text-[#ff6b6b]" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2" style={{ fontFamily: 'Space Grotesk' }}>Lightning Fast</h3>
                <p className="text-gray-600">Optimized for high-performance transactions</p>
              </div>
            </div>
          </div>

          {/* Email Signup Section */}
          <div className="bg-white p-8 rounded-2xl shadow-sm">
            <div className="max-w-xl mx-auto space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800" style={{ fontFamily: 'Space Grotesk' }}>Stay in the know</h2>
              <p className="text-gray-600">Join our waitlist for exclusive updates.</p>
              <EmailForm />
            </div>
          </div>

          {/* Launch Timeline */}
          <div className="space-y-6">
            <div className="flex items-center justify-center space-x-4">
              <div className="h-1 w-16 bg-[#9775fa]/20 rounded-full">
                <div className="h-1 w-16 bg-[#9775fa] rounded-full animate-pulse" />
              </div>
              <span className="text-lg font-semibold text-[#9775fa]" style={{ fontFamily: 'Space Grotesk' }}>Beta Launch: Q2.5 2025</span>
              <div className="h-1 w-16 bg-[#9775fa]/20 rounded-full">
                <div className="h-1 w-16 bg-[#9775fa] rounded-full animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;