import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import ConsensusAnimation from '../animations/ConsensusAnimation';

// Internal component for the animated background
const AnimatedBackground = ({ isInView }) => (
  <div className="absolute inset-0 pointer-events-none">
    <motion.div
      className="absolute inset-0"
      animate={isInView ? { backgroundPosition: ['0% 0%', '100% 100%'] } : {}}
      transition={{
        duration: 40,
        repeat: Infinity,
        repeatType: 'reverse'
      }}
      style={{
        backgroundImage: 'radial-gradient(circle at center, rgba(151, 117, 250, 0.2) 0%, transparent 8%)',
        backgroundSize: '40px 40px'
      }}
    />
  </div>
);

const Hero = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      id="Hero"
      className="container mx-auto px-4 pt-20 relative"
      aria-label="Hero section"
    >
      <AnimatedBackground isInView={isInView} />
     
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-5rem)]">
        <div className="text-center mb-6 lg:mb-12">
          <h5 className="text-7xl lg:text-8xl font-bold mb-3" style={{ fontFamily: 'Space Grotesk' }}>
            <span className="text-gray-800">Hello </span>
            <span className="text-gray-900">Block</span>
            <span
              className="bg-gradient-to-r from-[#3CB371] to-[#90EE90] text-transparent bg-clip-text"
              style={{ WebkitBackgroundClip: 'text' }}
            >
              tree
            </span>
          </h5>
          <h1 className="text-3xl lg:text-4xl font-semibold text-gray-600" style={{ fontFamily: 'Space Grotesk' }}>
            Break chains, build branches
            <span className="text-[#9775fa]">.</span>
          </h1>
        </div>
       
        <div className="w-full max-w-2xl mx-auto mb-8 lg:mb-16" ref={ref}>
          <div className="relative bg-white rounded-xl shadow-lg w-full aspect-video flex items-center justify-center">
            <ConsensusAnimation />
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.45 }}
          className="flex flex-col sm:flex-row gap-4 sm:space-x-4 text-center w-full sm:w-auto"
        >
          <motion.a
            href="#corefeatures"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center justify-center px-6 lg:px-8 py-3 bg-[#9775fa] text-white rounded-lg hover:bg-[#7b5fc8] transition-colors"
          >
            Learn More
          </motion.a>
          <motion.a
            href="#newsletter"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center justify-center px-6 lg:px-8 py-3 border-2 border-[#9775fa] text-gray-800 rounded-lg hover:bg-[#9775fa]/10 transition-colors"
          >
            Subscribe
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
