import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as LeafLogo } from '../../assets/leaf-logo.svg';

const footerLinks = {
  quickLinks: [
    { label: 'Whitepaper', href: './whitepaper.pdf' },
    { label: 'Documentation', href: '/coming-soon' },
    { label: 'GitHub', href: 'https://github.com/xLab-Inc' },
  ],
  social: [
    { label: 'Twitter', href: 'https://x.com/xtal_net' },
    { label: 'Discord', href: 'https://discord.gg/mh2p2KqvDm' },
    { label: 'Contact Us', href: 'mailto:info@xtal.network' },
  ],
};

export const Footer = () => {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          {/* Logo and Description */}
          <div className="space-y-8 xl:col-span-1">
            <motion.div
              whileHover={{ scale: 1.0737 }}
              whileTap={{ scale: 0.95 }}
              className="w-12 h-12 rounded-lg flex items-center justify-center"
            >
              <LeafLogo className="w-full h-full" />
            </motion.div>
            <p className="text-gray-600 text-base">
              Crystal Network | Dreams to Reality
            </p>
          </div>
          {/* Quick Links */}
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div>
              <h3 className="text-sm font-bold text-gray-900 tracking-wider uppercase">
                Quick Links
              </h3>
              <ul className="mt-4 space-y-4">
                {footerLinks.quickLinks.map(({ label, href }) => (
                  <li key={label}>
                    <a
                      href={href}
                      target={label === 'GitHub' ? '_blank' : undefined}
                      rel={label === 'GitHub' ? 'noopener noreferrer' : undefined}
                      className="text-base text-gray-600 hover:text-[#9775fa] transition-colors duration-200"
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Social Links */}
            <div>
              <h3 className="text-sm font-bold text-gray-900 tracking-wider uppercase">
                Connect
              </h3>
              <ul className="mt-4 space-y-4">
                {footerLinks.social.map(({ label, href }) => (
                  <li key={label}>
                    <a
                      href={href}
                      target={label !== 'Contact Us' ? '_blank' : undefined}
                      rel={label !== 'Contact Us' ? 'noopener noreferrer' : undefined}
                      className="text-base text-gray-600 hover:text-[#9775fa] transition-colors duration-200"
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-500 text-center">
            © {new Date().getFullYear()} Crystal Labs, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
