import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from "lucide-react";
import Button from "../../components/ui/Button";
import LoadingScreen from '../../components/shared/LoadingScreen';
import { ROUTES } from '../../components/shared/router/routes';

// WordPress content styles
const wordpressContentStyles = `
  /* Text Formatting */
  .blog-content {
    color: #374151;
    font-size: 1rem; /* Smaller font size */
    line-height: 1.75;
    white-space: pre-line; /* Preserve whitespace but wrap text */
  }

  .blog-content p {
    margin-bottom: 1.5rem;
    color: #374151;
  }

  .blog-content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #111827;
  }

  /* Lists */
  .blog-content ol, .blog-content ul {
    list-style-type: disc; /* Default for both lists */
    padding-left: 2rem;
    margin-bottom: 1.5rem;
  }

  .blog-content ol li, .blog-content ul li {
    margin-bottom: 0.75rem;
    padding-left: 0.5rem;
  }

  /* Make link styles more specific and !important */
  .blog-content a,
  .blog-content a:link,
  .blog-content a:active {
    color: #9775fa !important;
    text-decoration: underline !important;
  }

  .blog-content a:hover {
    color: #7950f2 !important;
  }

  /* Quotes */
  .blog-content blockquote {
    border-left: 4px solid #9775fa;
    padding-left: 1rem;
    margin: 1.5rem 0;
    font-style: italic;
  }
`;

// Custom button style
const buttonStyles = `
  .thick-border-button {
    border-width: 2px; /* Thicker border */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Slight shadow */
  }
`;

// Card Components with enhanced shadow
const Card = ({ children, className = '' }) => {
  return (
    <div 
      className={`
        bg-white 
        shadow-md /* Enhanced shadow */
        rounded-lg 
        border 
        border-gray-200 
        overflow-hidden
        ${className}
      `}
    >
      {children}
    </div>
  );
};

const CardContent = ({ children, className = '', noPadding = false }) => {
  return (
    <div 
      className={`
        ${noPadding ? '' : 'p-6'}
        ${className}
      `}
    >
      {children}
    </div>
  );
};

const API_CONFIG = {
  baseUrl: process.env.REACT_APP_WP_API_URL || 'https://xtal.network/wp-json/wp/v2',
  postsPerPage: 6
};

const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3, ease: 'easeInOut' }
};

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const slug = window.location.pathname.split('/blog/')[1];

  useEffect(() => {
    if (slug) {
      fetchPost();
    }
  }, [slug]);

  useEffect(() => {
    // Inject WordPress content styles and custom button style
    const styleSheet = document.createElement("style");
    styleSheet.innerText = wordpressContentStyles + buttonStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const processContent = (content) => {
    // Replace newlines between list items with spaces to prevent extra spacing
    content = content.replace(/(<\/li>)\s*\n\s*(<li>)/g, '$1$2');
    
    // Add proper spacing around paragraphs
    content = content.replace(/(<\/p>)\s*(<p>)/g, '$1\n\n$2');
    
    // Process numbered lists to ensure proper formatting
    content = content.replace(/(\d+\.\s)/g, '<br>$1');
    
    // Clean up any double spaces
    content = content.replace(/\s{2,}/g, ' ');
    
    return content;
  };

  const fetchPost = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_CONFIG.baseUrl}/posts?slug=${slug}&_embed`);
      
      if (!response.ok) throw new Error('Failed to fetch post');
      
      const [postData] = await response.json();
      if (!postData) throw new Error('Post not found');

      setPost({
        id: postData.id,
        title: decodeHTMLEntities(postData.title.rendered),
        content: processContent(postData.content.rendered),
        date: postData.date,
        author: postData._embedded?.author?.[0]?.name || 'Unknown Author',
        featuredImage: postData._embedded?.['wp:featuredmedia']?.[0]?.source_url || null,
        imageAlt: postData._embedded?.['wp:featuredmedia']?.[0]?.alt_text || '',
        categories: postData._embedded?.['wp:term']?.[0]?.map(cat => cat.name) || []
      });
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const decodeHTMLEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <main className="flex-grow bg-gray-50 pt-8"> {/* Adjusted top padding */}
          <LoadingScreen />
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <main className="flex-grow bg-gray-50 pt-8"> {/* Adjusted top padding */}
          <motion.div 
            className="max-w-5xl mx-auto py-16 px-4 flex items-center justify-center" /* Increased vertical padding */
            {...pageTransition}
          >
            <div className="text-center">
              <p className="text-red-600 mb-4">{error}</p>
              <Button
                onClick={() => window.location.href = ROUTES.BLOG}
                variant="outline" 
                className="hover:text-[#9775fa] transition-colors px-3 py-1.5 rounded-lg thick-border-button"
              >
                <ArrowLeft className="mr-2 h-4 w-4" /> Back to Blog
              </Button>
            </div>
          </motion.div>
        </main>
      </div>
    );
  }

  if (!post) return null;

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow bg-gray-50 pt-8"> {/* Adjusted top padding */}
        <div className="max-w-5xl mx-auto py-16 px-4"> /* Increased vertical padding */
          <Card>
            <CardContent className="pt-6">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-3xl font-bold text-gray-900 mb-4"
              >
                {post.title}
              </motion.h1>
              
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="flex flex-wrap items-center gap-4 text-sm text-gray-500 mb-8"
              >
                <span>{formatDate(post.date)}</span>
                <span>•</span>
                <span>By {post.author}</span>
                {post.categories.length > 0 && (
                  <>
                    <span>•</span>
                    <span>{post.categories.join(', ')}</span>
                  </>
                )}
              </motion.div>

              {post.featuredImage && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.3 }}
                  className="mb-8"
                >
                  <img
                    src={post.featuredImage}
                    alt={post.imageAlt}
                    className="rounded-lg w-full max-h-96 object-cover"
                    loading="lazy"
                  />
                </motion.div>
              )}

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="blog-content" /* Apply the blog-content class */
                dangerouslySetInnerHTML={{ __html: post.content }}
              />

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="mt-8 pt-8 border-t border-gray-200"
              >
                <Button 
                  onClick={() => window.location.href = ROUTES.BLOG}
                  variant="outline" 
                  className="hover:text-[#9775fa] transition-colors px-3 py-1.5 rounded-lg thick-border-button"
                >
                  <ArrowLeft className="mr-2 h-4 w-4" /> Back to Blog
                </Button>
              </motion.div>
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default BlogPost;
