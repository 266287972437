import React, { useState } from 'react';
import { motion } from 'framer-motion';

const CaseStudies = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const studies = [
    {
      title: "Financial Services",
      subtitle: "Cross-Border Transactions",
      description: "Revolutionizing cross-border settlements with sub-second transaction finality and regulatory compliance.",
      metrics: [
        { value: "99%", label: "Cost Reduction" },
        { value: "<3s", label: "Target Settlement Time" }
      ],
      ctaLink: "https://xtal.network/blog/crystal-network-the-future-of-global-remittances"
    },
    {
      title: "Healthcare",
      subtitle: "Data Security",
      description: "Securing universal and compliant health record permissioning while maintaining patient privacy.",
      metrics: [
        { value: "100%", label: "HIPAA Compliant" },
        { value: "4.5B+", label: "Potential Users" }
      ],
      ctaLink: "#"
    },
    {
      title: "Government",
      subtitle: "Social Infrastructure",
      description: "Modernizing land registry with transparent and reliable information infrastructure.",
      metrics: [
        { value: "TBD", label: "Annual Savings" },
        { value: "100%", label: "Uptime" }
      ],
      ctaLink: "#"
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  return (
    <section id="case-studies" className="py-20 relative bg-gray-50">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "50px" }}
        variants={containerVariants}
        className="text-center mb-12 px-4"
      >
        <h2 className="text-4xl font-bold mb-4">
          <span style={{ fontFamily: 'Space Grotesk' }}>Unchained Potential.</span>
        </h2>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "50px" }}
        variants={containerVariants}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 max-w-7xl mx-auto"
      >
        {studies.map((study, index) => (
          <motion.div
            key={study.title}
            variants={cardVariants}
            className="flex flex-col w-full bg-white rounded-2xl p-8 shadow-lg transform transition-transform duration-300 ease-out h-full"
            style={{
              transform: activeIndex === index ? 'scale(1)' : 'scale(0.95)'
            }}
            onMouseEnter={() => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            {/* Header */}
            <div className="border-b border-gray-200 pb-6 mb-6">
              <h3 className="text-2xl font-bold" style={{ color: '#9775fa' }}>{study.title}</h3>
              <p className="text-gray-500 text-sm mt-1">{study.subtitle}</p>
            </div>

            {/* Description */}
            <div className="mb-8 h-24">
              <p className="text-gray-600 leading-relaxed line-clamp-3">
                {study.description}
              </p>
            </div>

            {/* Metrics */}
            <div className="grid grid-cols-2 gap-4 mb-8">
              {study.metrics.map((metric) => (
                <div
                  key={metric.label}
                  className="bg-gray-50 p-6 rounded-lg shadow-sm h-24 flex flex-col justify-center items-center"
                >
                  <p style={{ color: '#9775fa' }} className="font-bold text-2xl">{metric.value}</p>
                  <p className="text-gray-500 text-sm mt-2 text-center">{metric.label}</p>
                </div>
              ))}
            </div>

            {/* Call to action */}
            <div className="mt-auto pt-4">
              <a
                href={study.ctaLink}
                className="inline-flex items-center group"
                style={{ color: '#9775fa' }}
              >
                Explore
                <span className="ml-2 transform transition-transform group-hover:translate-x-1">→</span>
              </a>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export default CaseStudies;
