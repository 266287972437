import React from 'react';
import { motion } from 'framer-motion';

const Button = ({ variant, children, className = '', ...props }) => {
  const baseStyles = 'px-6 py-3 rounded-lg transition-colors duration-200';
  const primaryStyles = `bg-[#9775fa] text-white hover:bg-[#7b5fc8]`;
  const secondaryStyles = `border border-[#9775fa] text-gray-800 hover:bg-[#9775fa]/10`;

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`${baseStyles} ${variant === 'primary' ? primaryStyles : secondaryStyles} ${className}`}
      {...props}
    >
      {children}
    </motion.button>
  );
};

export default Button;
