import React, { useState, useEffect } from 'react';
import { motion, useInView } from 'framer-motion';

const ChoreographedBlockProgression = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showLeaf, setShowLeaf] = useState(false);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });
  
  useEffect(() => {
    if (isInView) {
      setIsAnimating(true);
      
      const interval = setInterval(() => {
        setIsAnimating(prev => !prev);
      }, 4500);
      
      return () => clearInterval(interval);
    }
  }, [isInView]);

  useEffect(() => {
    if (!isAnimating) {
      // Show leaf after collapse animation completes
      const timer = setTimeout(() => {
        setShowLeaf(true);
      }, 1350); // Adjust timing to match exit sequence
      return () => clearTimeout(timer);
    } else {
      setShowLeaf(false);
    }
  }, [isAnimating]);

  const enterSequence = {
    grayCircle: { duration: 0.8, delay: 0 },
    leftSquare: { duration: 0.8, delay: 0.5 },
    middleSquare: { duration: 0.8, delay: 0.5 },
    leftLines: { duration: 1.2, delay: 0.75 },
    rightLines: { duration: 1.2, delay: 2.5 },
    purple: { duration: 1.3, delay: 2.0 },
    glow: { duration: 1.6, delay: 3. }
  };

  const exitSequence = {
    glow: { duration: 0.6, delay: 0.6 },
    leftLines: { duration: 0.6, delay: 0.6 },
    rightLines: { duration: 0.6, delay: 0.6 },
    grayCircle: { duration: 0.6, delay: 1.0 },
    leftSquare: { duration: 0.8, delay: 1.0 },
    middleSquare: { duration: 0.8, delay: 1.0 },
    purple: { duration: 0.6, delay: 1.0 }
  };

  const leafSequence = {
    duration: 0.7,
    ease: "easeOut"
  };

  return (
    <div ref={ref} className="w-full h-96 bg-slate-200 rounded-xl flex items-center justify-center">
      <svg viewBox="0 0 400 200" className="w-full h-full max-w-2xl">
        <defs>
          <filter id="glow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
          </filter>
        </defs>

        {/* Original animation elements */}
        {/* Left Lines */}
        <motion.g
          initial={{ opacity: 0 }}
          animate={{ opacity: isAnimating ? 1 : 0 }}
          transition={isAnimating ? enterSequence.leftLines : exitSequence.leftLines}
        >
          <motion.line
            x1="180" y1="70" x2="120" y2="90"
            stroke="#d1d5db" strokeWidth="2"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={isAnimating ? enterSequence.leftLines : exitSequence.leftLines}
          />
          
          <motion.line
            x1="180" y1="130" x2="120" y2="110"
            stroke="#d1d5db" strokeWidth="2"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={isAnimating ? enterSequence.leftLines : exitSequence.leftLines}
          />
        </motion.g>
        
        {/* Right Lines */}
        <motion.g
          initial={{ opacity: 0 }}
          animate={{ opacity: isAnimating ? 1 : 0 }}
          transition={isAnimating ? enterSequence.rightLines : exitSequence.rightLines}
        >
          <motion.line
            x1="280" y1="90" x2="220" y2="70"
            stroke="#d1d5db" strokeWidth="2"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={isAnimating ? enterSequence.rightLines : exitSequence.rightLines}
          />
          
          <motion.line
            x1="280" y1="110" x2="220" y2="130"
            stroke="#d1d5db" strokeWidth="2"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={isAnimating ? enterSequence.rightLines : exitSequence.rightLines}
          />
        </motion.g>

        {/* Gray Circle */}
        <motion.g
          initial={{ opacity: 0, x: -20 }}
          animate={{ 
            opacity: isAnimating ? 1 : 0, 
            x: isAnimating ? 0 : 100
          }}
          transition={isAnimating ? enterSequence.grayCircle : exitSequence.grayCircle}
        >
          <circle cx="100" cy="100" r="23" fill="#8b8b8b" />
          <ellipse cx="100" cy="100" rx="23" ry="10" fill="#8b8b8b" opacity="0.3" />
        </motion.g>
        
        {/* Left Square */}
        <motion.g
          initial={{ opacity: 0, x: -20 }}
          animate={{ 
            opacity: isAnimating ? 1 : 0,
            x: isAnimating ? 0 : 0,
            y: isAnimating ? 0 : 25
          }}
          transition={isAnimating ? enterSequence.leftSquare : exitSequence.leftSquare}
        >
          <rect x="180" y="50" width="40" height="40" rx="10" fill="#ff6b6b" />
          <rect x="180" y="55" width="40" height="40" rx="10" fill="#ff6b6b" opacity="0.3" />
        </motion.g>
        
        {/* Middle Square */}
        <motion.g
          initial={{ opacity: 0, x: -20 }}
          animate={{ 
            opacity: isAnimating ? 1 : 0,
            x: isAnimating ? 0 : 0,
            y: isAnimating ? 0 : -25
          }}
          transition={isAnimating ? enterSequence.middleSquare : exitSequence.middleSquare}
        >
          <rect x="180" y="110" width="40" height="40" rx="10" fill="#4dabf7" />
          <rect x="180" y="115" width="40" height="40" rx="10" fill="#4dabf7" opacity="0.3" />
        </motion.g>
        
        {/* Purple Circle */}
        <motion.g
          initial={{ opacity: 0 }}
          animate={{ 
            opacity: isAnimating ? 1 : 0,
            x: isAnimating ? 0 : -100
          }}
          transition={isAnimating ? enterSequence.purple : exitSequence.purple}
        >
          <circle cx="300" cy="100" r="23" fill="#9775fa" />
          <ellipse cx="300" cy="100" rx="23" ry="10" fill="#9775fa" opacity="0.3" />
        </motion.g>

        {/* Glow Effect */}
        <motion.g
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ 
            opacity: isAnimating ? 1 : 0,
            scale: isAnimating ? 1 : 0.95
          }}
          transition={isAnimating ? enterSequence.glow : exitSequence.glow}
          style={{ filter: 'url(#glow)' }}
        >
          <line x1="180" y1="70" x2="120" y2="90" stroke="#F4C430" strokeWidth="2.5" />
          <line x1="180" y1="130" x2="120" y2="110" stroke="#F4C430" strokeWidth="2.5" />
          <line x1="280" y1="90" x2="220" y2="70" stroke="#F4C430" strokeWidth="2.5" />
          <line x1="280" y1="110" x2="220" y2="130" stroke="#F4C430" strokeWidth="2.5" />
        </motion.g>

        {/* Leaf (appears after collapse) */}
        <motion.g
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ 
            opacity: showLeaf ? 1 : 0,
            scale: showLeaf ? 1 : 0.5
          }}
          transition={leafSequence}
        >
          {/* Ginkgo biloba leaf */}
          <path
            d="M200 75
               Q220 75, 235 90
               Q250 105, 240 120
               Q230 130, 200 140
               Q170 130, 160 120
               Q150 105, 165 90
               Q180 75, 200 75"
            fill="#90EE90"
          />
          {/* Center line */}
          <path
            d="M200 75
               L200 150"
            stroke="#2E7D32"
            strokeWidth="2"
          />
          {/* Characteristic veining */}
          <path
            d="M200 90
               Q220 100, 230 115
               M200 90
               Q180 100, 170 115"
            stroke="#2E7D32"
            strokeWidth="1"
            opacity="0.5"
          />
        </motion.g>
      </svg>
    </div>
  );
};

export default ChoreographedBlockProgression;
