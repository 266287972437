// BlockChoreography.js
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const BlockChoreography = ({ compact = false }) => {
  const [step, setStep] = useState(0);
  const timeoutsRef = useRef([]);

  useEffect(() => {
    const runSequence = () => {
      // Clear previous timeouts if any
      timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
      timeoutsRef.current = [];

      // Start each cycle from step 0
      setStep(0);

      
      timeoutsRef.current.push(
        setTimeout(() => setStep(1), 0),
        setTimeout(() => setStep(2), 500),
        setTimeout(() => setStep(3), 1000),
        setTimeout(() => setStep(4), 1337)
      );

      // If compact, loop every 5.0s
      if (compact) {
        timeoutsRef.current.push(
          setTimeout(() => runSequence(), 4300)
        );
      }
    };

    runSequence();

    return () => {
      // Cleanup on unmount
      timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
    };
  }, [compact]);

  // Adjust the viewBox and coordinates if compact
  const viewBox = compact ? "0 0 200 150" : "0 0 400 300";
  const centerX = compact ? 100 : 200;
  const centerY = compact ? 75 : 150;
  const radius = 20;
  
  // Left and right circle positions relative to the center
  const leftX = centerX - 50;
  const rightX = centerX + 50;
  
  // Offsets for side circles appearing
  const offset = compact ? 25 : 50; // Halfed for compact to maintain proportion

  return (
    <div className="w-full h-full flex items-center justify-center">
      <svg viewBox={viewBox} className="w-full h-full" preserveAspectRatio="xMidYMid meet">
        {/* Connection lines */}
        <motion.line
          stroke="#8b8b8b"
          strokeWidth="2"
          initial={{ x1: centerX, x2: centerX }}
          animate={{
            x1: step >= 2 && step < 4 ? leftX : centerX,
            x2: centerX
          }}
          y1={centerY}
          y2={centerY}
          transition={{ duration: 0.3 }}
        />
        <motion.line
          stroke="#8b8b8b"
          strokeWidth="2"
          initial={{ x1: centerX, x2: centerX }}
          animate={{
            x1: centerX,
            x2: step >= 2 && step < 4 ? rightX : centerX
          }}
          y1={centerY}
          y2={centerY}
          transition={{ duration: 0.3 }}
        />

        {/* Side blocks */}
        <motion.circle
          cx={leftX}
          cy={centerY}
          r={radius}
          fill="#ff6b6b"
          initial={{ scale: 0, x: offset }}
          animate={{
            scale: step >= 2 && step < 4 ? 1 : 0,
            x: step >= 2 && step < 4 ? 0 : offset
          }}
          transition={{ duration: 0.3 }}
        />
        <motion.circle
          cx={rightX}
          cy={centerY}
          r={radius}
          fill="#4dabf7"
          initial={{ scale: 0, x: -offset }}
          animate={{
            scale: step >= 2 && step < 4 ? 1 : 0,
            x: step >= 2 && step < 4 ? 0 : -offset
          }}
          transition={{ duration: 0.3 }}
        />

        {/* Gray underlayer circle */}
        <motion.circle
          cx={centerX}
          cy={centerY}
          r={radius}
          initial={{ scale: 0 }}
          animate={{
            scale: step >= 1 && step < 3 ? 1 : 0
          }}
          fill="#8b8b8b"
          transition={{ duration: 0.3 }}
        />

        {/* Purple circle on top */}
        <motion.circle
          cx={centerX}
          cy={centerY}
          initial={{ scale: 0, r: radius }}
          animate={{
            scale: step >= 3 ? 1 : 0,
            r: step >= 3 && step < 4 ? radius + 2 : radius
          }}
          fill="#9775fa"
          transition={{
            duration: 0.3,
            scale: { duration: 0.3 },
            r: { duration: 0.2 }
          }}
        />
      </svg>
    </div>
  );
};

export default BlockChoreography;
