import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Header } from './components/layout/Header';
import Hero from './components/sections/Hero';
import CoreFeatures from './components/sections/CoreFeatures';
import CaseStudies from './components/sections/CaseStudies';
import Newsletter from './components/sections/Newsletter';
import Footer from './components/layout/Footer';
import LoadingScreen from './components/shared/LoadingScreen';
import BlogPage from './pages/blog/BlogPage';
import BlogPost from './pages/blog/BlogPost';
import ComingSoon from './pages/ComingSoon';
import { ROUTES } from './components/shared/router/routes';

const App = () => {
  const skipToMain = () => {
    document.getElementById('main-content').focus();
  };

  return (
    <Router>
      <>
        <a
          href="#main-content"
          onClick={skipToMain}
          className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 focus:z-50 focus:px-4 focus:py-2 focus:bg-white focus:text-gray-800"
        >
          Skip to main content
        </a>
        <div className="min-h-screen bg-[#f4f5f7] relative">
          <Header />
          <Routes>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.BLOG} element={<BlogPage />} />
            <Route path={ROUTES.BLOG_POST} element={<BlogPost />} />
            <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
          </Routes>
          <Footer />
        </div>
      </>
    </Router>
  );
};

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  return (
    <>
      <AnimatePresence mode="wait">
        {loading && <LoadingScreen onComplete={() => setLoading(false)} />}
      </AnimatePresence>
      <motion.main
        id="main-content"
        tabIndex="-1"
        className="text-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: loading ? 0 : 1 }}
        transition={{ duration: 0.6 }}
      >
        <Hero />
        <CoreFeatures />
        <CaseStudies />
        <Newsletter />
      </motion.main>
    </>
  );
};

export default App;
