import React from 'react';
import { motion } from 'framer-motion';
import BlockChoreography from '../animations/BlockChoreography';

const LoadingScreen = ({ onComplete }) => {
  return (
    <motion.div
      className="fixed inset-0 bg-[#f4f5f7] flex items-center justify-center z-50"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      onAnimationComplete={() => {
        if (onComplete) {
          onComplete();
        }
      }}
    >
      <div className="w-64 h-64 md:w-80 md:h-80 lg:w-96 lg:h-96">
        <BlockChoreography />
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
