import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowLeft, ArrowRight } from "lucide-react";
import { ROUTES } from '../../components/shared/router/routes';
import PostCard from './PostCard';
import Button from '../../components/ui/Button';
import LoadingScreen from '../../components/shared/LoadingScreen';

const API_CONFIG = {
  baseUrl: process.env.NEXT_PUBLIC_WP_API_URL || 'https://xtal.network/wp-json/wp/v2',
  postsPerPage: 9,
};

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1200
  );
  const [activeCard, setActiveCard] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef(null);

  const getCardsToShow = useCallback(() => {
    if (windowWidth < 768) return 1;      
    if (windowWidth < 1280) return 2;     
    return 3;                             
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setSlideIndex(0);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const fetchPosts = useCallback(async () => {
    try {
      setLoading(true); // Start loading
      const response = await fetch(
        `${API_CONFIG.baseUrl}/posts?page=${page}&per_page=${API_CONFIG.postsPerPage}&_embed`
      );

      if (!response.ok) throw new Error('Failed to fetch posts');

      const totalPages = parseInt(response.headers.get('X-WP-TotalPages'), 10);
      setTotalPages(totalPages);

      const data = await response.json();
      setPosts(data.map(post => ({
        id: post.id,
        slug: post.slug,
        title: decodeHTMLEntities(post.title.rendered),
        excerpt: extractExcerptText(post.excerpt.rendered),
        date: new Date(post.date).toLocaleDateString(),
        author: post._embedded?.author?.[0]?.name || 'Unknown Author',
        featuredImage: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '/api/placeholder/400/300',
        imageAlt: post._embedded?.['wp:featuredmedia']?.[0]?.alt_text || 'Blog post featured image',
        href: ROUTES.BLOG_POST.replace(':slug', post.slug)
      })));
    } catch (err) {
      setError('Failed to fetch posts. Please try again later.');
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false); // Stop loading
    }
  }, [page]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const decodeHTMLEntities = (text) => {
    if (typeof window === 'undefined') return text;
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  const extractExcerptText = (html) => {
    if (typeof window === 'undefined') return html;
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setDragStartX(e.targetTouches[0].clientX);
    setDragOffset(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const currentX = e.targetTouches[0].clientX;
    const diff = currentX - dragStartX;
    setDragOffset(diff);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const minSwipeDistance = 50;
    const distance = dragOffset;

    if (Math.abs(distance) >= minSwipeDistance) {
      if (distance > 0 && slideIndex > 0) {
        handleSlidePrev();
      } else if (distance < 0 && slideIndex < maxSlideIndex) {
        handleSlideNext();
      }
    }

    setDragOffset(0);
  };

  const handleSlidePrev = () => {
    setSlideIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSlideNext = () => {
    setSlideIndex((prevIndex) => Math.min(prevIndex + 1, maxSlideIndex));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSlideIndex(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) return <LoadingScreen />;
  if (error) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center max-w-md">
          {error}
        </div>
      </div>
    );
  }

  const cardsToShow = getCardsToShow();
  const maxSlideIndex = Math.max(0, posts.length - cardsToShow);

  return (
    <div className="min-h-screen flex flex-col max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
      <main className="flex-grow py-24">
        <div className="text-center mb-12 pt-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-8">Blog</h1>
          <h2 className="text-3xl font-semibold text-[#9775fa] mb-3">Latest Updates</h2>
          <p className="text-lg text-gray-600">
            Stay informed with our latest news and insights
          </p>
        </div>

        <div className="relative px-4 sm:px-8">
          <div 
            className="overflow-hidden pb-8"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              ref={sliderRef}
              className="flex transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(calc(-${(slideIndex * 100) / cardsToShow}% + ${dragOffset}px))`
              }}
            >
              {posts.map((post, index) => (
                <div 
                  key={post.id}
                  className="px-4 h-full"
                  style={{ 
                    flex: `0 0 ${100 / cardsToShow}%`,
                    maxWidth: `${100 / cardsToShow}%`
                  }}
                  onMouseEnter={() => setActiveCard(index)}
                  onMouseLeave={() => setActiveCard(null)}
                >
                  <div 
                    className={`
                      h-full rounded-2xl overflow-hidden
                      shadow-md 
                      transition-all duration-300 ease-out
                      hover:shadow-lg
                      ${activeCard === index ? 'scale-[1.02]' : 'scale-100'}
                    `}
                  >
                    <PostCard post={post} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation arrows moved below cards */}
          {posts.length > cardsToShow && (
            <div className="flex items-center justify-center gap-4 mt-8">
              <Button
                variant="secondary"
                onClick={handleSlidePrev}
                disabled={slideIndex === 0}
                className={`
                  h-10 w-10
                  flex items-center justify-center 
                  rounded-full 
                  bg-white 
                  shadow-md hover:shadow-lg 
                  transition-all duration-300
                  !p-0
                  disabled:opacity-50
                `}
                aria-label="Previous Slide"
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>

              {/* Added slide indicators */}
              <div className="flex gap-2">
                {Array.from({ length: Math.ceil(posts.length / cardsToShow) }).map((_, idx) => (
                  <button
                    key={idx}
                    className={`
                      w-2 h-2 rounded-full transition-all duration-300
                      ${slideIndex === idx ? 'bg-[#9775fa] w-4' : 'bg-gray-300'}
                    `}
                    onClick={() => setSlideIndex(idx)}
                    aria-label={`Slide ${idx + 1}`}
                  />
                ))}
              </div>

              <Button
                variant="secondary"
                onClick={handleSlideNext}
                disabled={slideIndex >= maxSlideIndex}
                className={`
                  h-10 w-10
                  flex items-center justify-center 
                  rounded-full 
                  bg-white 
                  shadow-md hover:shadow-lg 
                  transition-all duration-300
                  !p-0
                  disabled:opacity-50
                `}
                aria-label="Next Slide"
              >
                <ArrowRight className="h-5 w-5" />
              </Button>
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-between items-center mt-12">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="shadow-md hover:shadow-lg transition-shadow duration-300"
              aria-label="Previous Page"
            >
              <ArrowLeft className="mr-2 h-4 w-4" /> Previous
            </Button>
            <span className="text-sm text-gray-600">
              Page {page} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              className="shadow-md hover:shadow-lg transition-shadow duration-300"
              aria-label="Next Page"
            >
              Next <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        )}
      </main>
    </div>
  );
};

export default BlogPage;
